/*************************************************
 * 통합플랫폼(PFM) API 호출을 위한 공통 Helper 스크립트
 *************************************************/
import { makeStdEtxtCrtSysNm } from '@/api/sc-legacy/index'; // AS-IS 디채 채널 공통부 포맷 재사용
import Modal from '@/utils/modals';
import CommonUtil from '@/utils/common-util';
import TypeUtil from '@/utils/type-util';
import { isPfmUrl, toPfmUrl } from '@/utils/url-parser';

let txSeq = 0; // 거래순번

/**
 * 통합플랫폼(PFM) API 호출 공통부(common)를 생성하여 리턴한다.
 * @param {Object} data 요청 데이터
 * @param {Boolean} hasPrivateInfo 개인정보포함여부
 * @param {Object} context dayjs 인스턴스가 주입되어 있는 Nuxt.js Context or Vue인스턴스 VM(Vue Model)
 * @returns 통합플랫폼(PFM) API 호출 공통부(common)
 */
export const makeCommonData = (data = {}, hasPrivateInfo = false, context = window?.$nuxt?.context) => {
  const yyyyMMddHHmmssSSS = context.$dayjs().format('YYYYMMDDHHmmssSSS');
  return {
    ...data?.common,
    // dlngEvnDvC: svId?.substr(10, 1) || 'S',
    scrnId: CommonUtil.getScrnId(),
    stdEtxtCrtDt: yyyyMMddHHmmssSSS.substr(0, 8),
    stdEtxtCrtSysNm: makeStdEtxtCrtSysNm(),
    stdEtxtSn: yyyyMMddHHmmssSSS.substr(8) + context.$stringUtil.padString(++txSeq, 5, '0'),
    stdEtxtPrgDvNo: 0,
    stdEtxtPrgNo: 0,
    indvInfIncYn: hasPrivateInfo ? 'Y' : 'N', // 개인정보포함여부 (For ActionLog) INDV_INF_INC_YN 	: 'Y'/'N'
    usid: context.$storageUtil?.store?.get('loginUser')?.mnmCstMngtNo ?? 'USERID0',
  };
};

/**
 * 통합플랫폼(PFM) API 호출 공통부(common)를 포함한 전체 요청데이터 생성
 * @param {Object} data 요청 데이터
 * @param {Boolean} hasPrivateInfo 개인정보포함여부
 * @param {Object} context axios 인스턴스가 주입되어 있는 Nuxt.js Context or Vue인스턴스 VM(Vue Model)
 * @returns 통합플랫폼(PFM) API 호출 스펙에 맞는 API 요청데이터
 */
export const makeRequestData = (data = {}, hasPrivateInfo = false, context = window?.$nuxt?.context) => {
  // Deep Copy 원본 데이터 가공 방지
  const _data = JSON.parse(JSON.stringify(data));
  _data.common = makeCommonData(data, hasPrivateInfo, context);
  return _data;
};

/**
 * 통합플랫폼(PFM) API 호출
 *
 * @param {String | Object} urlInfo 요청 URL 정보
 * @param {Object} data 요청 데이터
 * @param {Boolean} dontHandlerErrors 공통 에러처리를 원하지 않는경우 설정 (기본값=false 로 공통에서 에러 핸들링)
 * @param {Boolean} hasPrivateInfo 개인정보포함여부
 * @param {Object} context axios 인스턴스가 주입되어 있는 Nuxt.js Context or Vue인스턴스 VM(Vue Model)
 * @returns 통합플랫폼(PFM) API 호출 결과 데이터
 */
export const callApi = async (
  urlInfo,
  data,
  dontHandlerErrors = false,
  hasPrivateInfo = false,
  context = window?.$nuxt?.context,
) => {
  let url = '';
  let stackTrace;
  try {
    // Sentry 전송을 위한 호출 직전까지의 스택 트레이스 저장
    // eslint-disable-next-line unicorn/error-message
    const { stack } = new Error();
    stackTrace = stack;

    let progress = true;

    if (TypeUtil.isString(urlInfo)) {
      url = urlInfo;
    } else if (TypeUtil.isPlainObject(urlInfo)) {
      url = urlInfo?.url;
      progress = urlInfo?.progress ?? true;
    }

    context.$log.log(`[공통-PFMAPI][REQUEST][${url}] data: `, data);

    const response = await context.$pfm.$post(url, makeRequestData(data, hasPrivateInfo, context), { progress });

    context.$log.log(`[공통-PFMAPI][RESPONSE][${url}] response: `, response);

    // Sentry 전송을 위한 요청 객체 전달
    return responseHandler(response, context, data);
  } catch (error) {
    // Sentry 전송을 위한 호출 직전까지의 스택 트레이스 바인딩
    error.stack = stackTrace;

    errorHandler(error, dontHandlerErrors, url, context);
  }
};

/**
 * 통합플랫폼(PFM) API 응답 결과 공통 처리 핸들러
 * @param {Object} data
 * @param {Object} context ongobirdge 인스턴스가 주입되어 있는 Nuxt.js Context or Vue인스턴스 VM(Vue Model)
 * @param {Object} request API 요청 객체
 * @returns
 */
export const responseHandler = (data, context = window?.$nuxt?.context, request) => {
  // 성공여부(success) 가 true가 아니라면 오류이다.
  if (!data.success) {
    // 예외를 던져 에러 처리 핸들러가 catch하게 한다.
    const err = new Error(data?.message?.msgKrnCn ?? '처리 도중 오류가 발생하였습니다.');
    err.msgC = data?.message?.msgC;
    err.msgKrnCn = data?.message?.msgKrnCn;

    // Sentry 전송을 위해 에러 객체에 요청, 응답 정보 저장
    err.request = request;
    err.response = { data };

    throw err;
  }

  // 미션유형코드가 시크릿미션, 주니어웰컴미션인 경우 커튼콜 호출X
  if (data?.missionResult?.mnmMisnTpc === '3' || data?.missionResult?.mnmMisnTpc === '5') {
    return data;
  }

  // 미션유형코드가 모니몬집짓기미션(7: 1탄, 8: 2탄) - 젤리 없는 커튼콜 호출
  if (data?.missionResult?.mnmMisnTpc === '7' || data?.missionResult?.mnmMisnTpc === '8') {
    // 영향도가 있어, 7,8 내에서만 신규 네이티브 커튼콜 호출 처리
    // 특정 앱버전(10.5.0) 이상에서만 커튼콜 호출
    const isActiveAppVer = context.$appUtil.activateService({
      aos: '10.5.0',
      ios: '10.5.0',
    });

    context.$log.log('[공통-PFMAPI][모니몬집짓기-커튼콜] isActiveAppVer ', isActiveAppVer);

    if (!isActiveAppVer) {
      return data;
    }

    const connUrl = data?.missionResult?.connUrl ?? '';
    const url = isPfmUrl(connUrl) ? toPfmUrl(connUrl) : connUrl;
    const title = data?.missionResult?.misnTitNm ?? '';
    const curtainType = 'mission'; // 커튼콜 타입 (default:jelly)

    context.$log.log('[공통-PFMAPI][모니몬집짓기-커튼콜] callBridge ', connUrl, url, title, curtainType);
    context.$ongobridge.openMissionPopup({ url, title, curtainType });
    return data;
  }

  // 미션달성여부가 'Y' 이면 미션달성 브릿지 호출
  if (data?.missionResult?.misnAchvYn === 'Y') {
    context.$ongobridge.openMissionPopup({
      url: isPfmUrl(data?.missionResult?.connUrl)
        ? toPfmUrl(data?.missionResult?.connUrl)
        : data?.missionResult?.connUrl,
      title: data?.missionResult?.misnTitNm,
    });
  }

  return data;
};

/**
 * 통합플랫폼(PFM) API 에러 처리 핸들러
 * @param {Object} error
 * @param {Boolean} dontHandlerErrors 공통 에러처리를 원하지 않는경우 설정 (기본값=false 로 공통에서 에러 핸들링)
 * @param {String} url 요청 URL
 */
export const errorHandler = (error, dontHandlerErrors = false, url, context = window?.$nuxt?.context) => {
  // 핸들링 여부와 상관없이 Sentry 전송
  const { errorObject, errorType } = context.$sentryUtil.getError(error);
  context.$sentryUtil.captureException(errorObject, errorType);

  // 공통 에러처리를 원하지 않는경우(직접 핸들링 하고자 할 때),
  // 에러를 그대로 throw 시킨다.
  if (dontHandlerErrors) throw error;

  // 기본 공통 alertMsg
  let alertMsg = '앗, 일시적으로<br />시스템과의 연결이 불안정합니다. <br />잠시 후 다시 확인해 주세요.';
  // error 객체의 message를 errorMessage라는 변수명으로 받는다.
  const { request, response, msgC, message: errorMessage } = error;
  context.$log.log('[공통-PFMAPI] errorHandler response: ', response);
  context.$log.log('[공통-PFMAPI] errorHandler msgC: ', msgC);
  context.$log.log('[공통-PFMAPI] errorHandler errorMessage: ', errorMessage);

  let sessionExpActive = false;
  if (context.$appUtil.getAppInfo('mblSappYn') === 'Y') {
    // 세션 만료 관련 인터페이스 적용된 버전 체크
    sessionExpActive = context.$appUtil.activateService({
      ios: '10.1.6',
    });
  } else if (CommonUtil.isDev()) {
    // 개발 로컬에서는 무조건 보임 (테스트)
    sessionExpActive = true;
  }
  context.$log.log('[공통-PFMAPI] errorHandler sessionExpActive: ', sessionExpActive);

  // 오류 메시지 처리
  if (msgC === 'EBPFM0126' && sessionExpActive) {
    // 특정코드, 세션 만료 시 모바일 인터페이스 호출
    if (CommonUtil.isMonimoBridgeApp()) {
      context.$mnmBridge.callBridge(context.$mnmBridge.FAIL_SESSION);
    } else {
      context.$ongobridge.sessionExpired();
    }
    return;
  } else if (response) {
    // axios 호출 성공하여 response를 받은 경우
    const { status, data: errorData } = error?.response;

    if (status === 200) {
      alertMsg = errorMessage || errorData;
    } else if (status === 503) {
      alertMsg = '앗, 일시적으로<br />시스템과의 연결이 불안정 합니다. <br />잠시 후 다시 확인해 주세요.';
    } else {
      alertMsg = '앗, 일시적으로<br />시스템과의 연결이 불안정합니다. <br />잠시 후 다시 확인해 주세요.';
    }
  } else if (request?.status === 0) {
    // 네트워크로의 요청이 아예 못나간 상황 오류 무시. (pause / cancel 등등)
    return;
  } else if (errorMessage) {
    // 그 외 axiosError는 아니나 Error가 throw 된 경우 errorMessage를 넣음.
    alertMsg = errorMessage;
  }

  // 메시지 상자 열기
  const messageDialogData = {
    id: 'pfmErrorMsg',
    contentText: alertMsg?.replace(/[\r\n]+/g, '<br/>'),
  };

  // 메시지 다이얼로그 아이콘 설정 ( I/N 에 대해서는 아이콘 삭제 처리 )
  if (msgC?.startsWith('I') || msgC?.startsWith('N')) {
    messageDialogData.iconName = '';
  }

  // 메시지 다이얼로그 띄움
  Modal.messageDialogData(messageDialogData);
};
