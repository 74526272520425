/********************************
 * MDP(마이데이터) API 스크립트
 ********************************/
import { callMdpApi } from '~/api/mdp';
import { callApi } from '~/api/pfm';

/**
 * 계좌 Home 조회
 * @returns
 */
export const acHomItgApi = async data => {
  return await callMdpApi('/ac/ac-hom-itg', data);
};

/**
 * 계좌 편집 리스트 조회
 * @returns
 */
export const acListEdit = async () => {
  return await callMdpApi('/ac/ac-list-edit');
};

/**
 * 계좌 편집 저장
 * @returns
 */
export const acOrdHid = async acOdrHidVOList => {
  return await callMdpApi('/ac/ac-ord-hid', { acOdrHidVOList }, true);
};

/**
 * 계좌(홈) - 계좌 정보 조회
 * @returns
 */
export const acInf = async accountNum => {
  return await callMdpApi('/ac/ac-inf', { accountNum }, true);
};

/**
 * 계좌 내역 조회
 * @returns
 */
export const acIzBas = async data => {
  return await callMdpApi('/ac/ac-iz-bas', { ...data }, true);
};

/**
 * 계좌 상세 조회(거래 상세 및 Filter, 기간별 거래내역만 조회)
 * @returns
 */
export const acIzBasDtl = async data => {
  return await callMdpApi('/ac/ac-iz-bas-dtl', { ...data }, true);
};

/**
 * 계좌 별명 변경
 * @returns
 */
export const acNcknmeChg = async data => {
  return await callMdpApi('/ac/ac-ncknme-chg', { ...data }, true);
};

/**
 * 출금 가능한 계좌 목록 조회
 * @returns
 */
export const wdrwPsbAc = async () => {
  return await callMdpApi({ url: '/ac/wdrw-psb-ac', progress: false });
};

/**
 * 모니모 운영 자동이체 건수 조회
 * @returns
 */
export const selCntAftnAplc = async data => {
  return await callApi('/restapi/mny/autotransfer/selCntAftnAplc', data, true);
};

/**
 * 모니모 운영 암호화 계좌목록 조회
 * @returns
 */
export const selListRgAcMdp = async data => {
  return await callApi('/restapi/opb/assetremt/selListRgAcMdp', data, true);
};

/**
 * 모니모 운영 암호화 계좌목록 조회
 * @returns
 */
export const selListSyncRgAcInfo = async (data = { newInqrYn: 'N' }) => {
  return await callApi({ url: '/restapi/opb/asset/selListSyncRgAcInfo', progress: false }, data, true);
};

/**
 * 모니모 운영 계좌번호ID 계좌목록 조회
 * @returns
 */
export const selOpbRgAcno = async data => {
  return await callApi({ url: '/restapi/opb/asset/selOpbRgAcno', progress: false }, data, true);
};

/**
 * 마이데이터 입출금 계좌 등록 여부 조회
 */
export const pywdAcIncYn = async (data, progress = true) => {
  return await callMdpApi({ url: '/ac/pywd-ac-inc-yn', progress }, data);
};

/**
 * 마이데이터 입출금 계좌리스트 조회
 */
export const mnmRemtAc = async (data, progress = true) => {
  return await callMdpApi({ url: '/ac/mnm-remt-ac', progress }, data);
};

/**
 * 해지 계좌 연결 끊기
 */
export const rsgAcConnCut = async (data, progress = true) => {
  return await callMdpApi({ url: '/ac/rsg-ac-conn-cut', progress }, data);
};

/**
 * 계좌번호 조회
 */
export const acAccountNum = async data => {
  return await callMdpApi({ url: '/ac/account-num', progress: false }, data);
};

/**
 * 분석 탭 - 탭 메인 api 호출
 */
export const acAnalyMain = async data => {
  return await callMdpApi({ url: '/ac/ana/main', progress: false }, data);
};

/**
 * 분석 탭 - 계좌비중 상세 api 호출
 */
export const acWeit = async data => {
  return await callMdpApi({ url: '/ac/ana/ac-weit', progress: false }, data);
};

/**
 * 분석 탭 - 예적금 예상이자 상세 api 호출
 */
export const disEtInt = async data => {
  return await callMdpApi({ url: '/ac/ana/dis-et-int', progress: false }, data);
};

/**
 * 분석 탭 - 예적금 비교 상세 api 호출
 */
export const disAcCmpr = async data => {
  return await callMdpApi({ url: '/ac/ana/dis-ac-cmpr', progress: false }, data);
};

/**
 * 분석 탭 - 종잣돈 모으기_설정_조회 api 호출
 */
export const psnzInfo = async data => {
  return await callMdpApi({ url: '/ac/ana/psnz-info', progress: false }, data);
};

/**
 * 분석 탭 - 종잣돈 모으기_설정_계좌목록 조회 api 호출

 * */
export const psnzAcList = async data => {
  return await callMdpApi({ url: '/ac/ana/psnz-ac-list', progress: false }, data);
};

/**
 * 분석 탭 - 종잣돈 모으기 설정_저장 api 호출
 */
export const psnzInsert = async data => {
  return await callMdpApi({ url: '/ac/ana/psnz-insert', progress: false }, data);
};

/**
 * 분석 탭 - 종잣돈 모으기 삭제 api 호출
 */
export const psnzDelete = async data => {
  return await callMdpApi({ url: '/ac/ana/psnz-delete', progress: false }, data);
};

/**
 * 계좌 - 모니모통장 조회
 */
export const accountMonimoInfo = async data => {
  return await callMdpApi('/ac/account-monimoInfo', data, true);
};
