/********************************
 * MDP(마이데이터) 테스트용 API
 ********************************/
import { callMdpApi } from '@/api/mdp';
// import { callApi } from '@/api/pfm';

/**
 * 임시로그인
 * @returns
 */
export const mydataLogin = async (cstMngtNo, context = window?.$nuxt?.context) => {
  const result = await callMdpApi(
    '/mydata-member/templogin',
    {
      cstMngtNo,
    },
    true,
  );
  if (result && result.success) {
    context.$log.log('[마데API-test]  로그인 성공!');
    context.$log.log('[마데API-test] scssn:' + result.payload.scssn);
    context.$log.log('[마데API-test] encst:' + result.payload.encst);
    context.$storageUtil.sessionStorage.set('scssn', result.payload.scssn);
    context.$storageUtil.sessionStorage.set('encst', result.payload.encst);
    return true;
  } else {
    context.$log.log('[마데API-test] temp Login error');
    context.$storageUtil.sessionStorage.set('scssn', '');
    context.$storageUtil.sessionStorage.set('encst', '');
    return false;
  }
};
